import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import StorageUpdate from "./StorageUpdate";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchStorageUpdate() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.STORAGE, id, {"to_update" : 1})}
            /* 
            * StorageUpdate 内で useForm を使っているためこのように書く.
            * (Rendered more hooks than during the previous render というエラー対策)
            */
            renderSuccess={(data) => <StorageUpdate data={data.data} />}
        />
    );
}
