import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import UserDelete from "./UserDelete";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchUserDelete() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.USER, id)}
            renderSuccess={(data) => <UserDelete data={data.data} />}
        />
    );
}
