import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import {URL, ACTION} from './URL';
import { _l, _d } from './util';
import Button from 'react-bootstrap/Button';


function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  /*
  type LoginDataType = {
    email: string;
    password: string;
  };
*/

const { 
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // ログインボタンを押した時のみバリデーションを行う
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async (data) => {
    _d(data, "onSubmit data: ")
    clearErrors();
    const url       = new URL(ACTION.LOGIN);
    const response  = await url.fetch(data);
    _d(response, "onSubmit response: ")

    if (response.ok) {
      const data    = await response.json();
      _d(data, "response.ok data: ")
      const user_id = data.user_id;
      const role    = data.role;
      if (user_id > 0) {
        console.log('ログイン成功: user_id = ' + user_id + ', role = ' + role);
        login(user_id, role);
        navigate(URL.PATH(ACTION.TOP));
        return;
      }
    } else {
      _l("response ng")
      // TODO: refactoring.
      response.json()
      .then(data => {
        _d(data, "then data: ")
        for (let name of ['email', 'password', 'msg']) {
          const message = data[name];
          if (!message) continue;
          setError(name, { type: 'server', message: message });
        }
        return;
      })
    }

    setError('msg', { type: 'manual', message: 'ログインに失敗しました' });
  };

  return (
    <div className="Login">
      <h1>ログイン</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            id="email"
            name="email"
            placeholder="メールアドレス"
            {...register('email', {
              required: {
                value: true, 
                message: 'メールアドレスを入力してください',
              },
              onChange: () => clearErrors()
              /*
              pattern: {
                value: /^[0-9]{8}$/,
                message: '8桁の数字のみ入力してください。',
              },
              */
            })} 
          />
            {errors.email?.message && <div className='error'>{errors.email.message}</div>}
        </div>
        <div>
          <input
            id="password"
            name="password"
            placeholder="パスワード"
            type="password"
            {...register('password', { 
              required: {
                value: true,
                message: 'パスワードを入力してください'
              },
              onChange: () => clearErrors()
            })} 
            />
            {errors.password?.message && <div className='error'>{errors.password.message}</div>}
        </div>
        {errors.msg?.message && <div className='error'>{errors.msg.message}</div>}
        <Button type="submit">ログイン</Button>
      </form>
    </div>
  );
}

export default Login;
