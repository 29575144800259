import Fetch from "../Fetch";
import LogList from "./LogList";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchLogList() {
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.LOG)}
            renderSuccess={(data) => <LogList data={data.data} />}
        />
    );
}
