import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import StorageDelete from "./StorageDelete";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchStorageDelete() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.STORAGE, id)}
            renderSuccess={(data) => <StorageDelete data={data.data} />}
        />
    );
}
