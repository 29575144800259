import Fetch from "../Fetch";
import UserPwchange from "./UserPwchange";
import { URL, TYPE, ACTION } from "../URL";
import { get_user_id } from "../AuthProvider";

export default function FetchUserPwchange() {
    const id = get_user_id();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.USER, id)}
            /* 
            * UserUpdate 内で useForm を使っているためこのように書く.
            * (Rendered more hooks than during the previous render というエラー対策)
            */
            renderSuccess={(data) => <UserPwchange data={data.data} />}
        />
    );
}
