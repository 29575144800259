import Fetch from "../Fetch";
import ItemList from "./ItemList";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchItemResult() {
    return (
        <Fetch
            url={new URL(ACTION.RESULT, TYPE.ITEM)}
            renderSuccess={(data) => (
                <div>
                    <h2>以下 {data.data.length} 個の備蓄品を登録しました</h2>
                    <ItemList data={data.data} />
                </div>
            )}
        />
    );
}
