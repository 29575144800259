import Fetch from "./Fetch";

export default function FetchStatus() {
    return (
        <Fetch
            path="api/status/"
            renderSuccess={Status}
        />
    );
}

function Status({data}) {
    return (
        <div>
            <h1>Status</h1>
            <div>
                <p>session_key:         {data.session_key}</p>
                <p>session_data:        {data.session_data}</p>
                <p>user:                {data.user}</p>
                <p>is_authenticated:    {data.is_authenticated ? "true" : "false"}</p>
                <p>csrf_token:          {data.csrf_token}</p>
            </div>
        </div>
    );
}
