import { useParams, useLocation } from "react-router-dom";
import Fetch from "./Fetch";
import { URL, TYPE, ACTION } from "./URL";
import { BarcodeScanner } from "./BarcodeScanner";

function getActionFromQueryParams(location) {
    let queryParams = new URLSearchParams(location.search);
    let bound       = queryParams.get('bound');
    let action      = URL.findAction(bound);
    if (action === ACTION.OUTBOUND) return ACTION.OUTBOUND;
    else                            return ACTION.INBOUND;
}

export default function FetchScan() {
    let { id } = useParams();
    let action = getActionFromQueryParams(useLocation());

    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.STORAGE, id)}
            renderSuccess={(data) => <Scan data={data.data} action={action} />}
        />
    );
}

export function Scan({data, action}) {
    return <BarcodeScanner storage={data} url={new URL(action)} />;
}
