import ItemList from '../Items/ItemList';
import Table from 'react-bootstrap/Table';

export default function StorageDetail({data}) {
    return (
        <>
        <h3>■倉庫情報</h3>
        <Table bordered size="sm">
            <tbody>
                <tr>
                    <th>倉庫ID</th>
                    <td>{data.id}</td>
                </tr>
                <tr>
                    <th>名前</th>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <th>住所</th>
                    <td>{data.address}</td>
                </tr>
            </tbody>
        </Table>
        <h3>■備蓄品一覧</h3>
        <ItemList data={data.items} displaySort={false} />
        </>
    );
}
