import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import StorageDetail from "./StorageDetail";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchStorageDetail() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.STORAGE, id)}
            renderSuccess={StorageDetail}
        />
    );
}
