import { Routes, Route } from "react-router-dom";
import UserInsert from "./UserInsert";
import FetchUserUpdate from "./FetchUserUpdate";
import FetchUserDelete from "./FetchUserDelete";
import FetchUserDetail from "./FetchUserDetail";
import FetchUserList from "./FetchUserList";
import FetchUserPwchange from "./FetchUserPwchange";

export default function App() {
  return (
    <Routes>
      <Route path="user_insert/"    element={<UserInsert />} />
      <Route path="user_update/:id" element={<FetchUserUpdate />} />
      <Route path="user_delete/:id" element={<FetchUserDelete />} />
      <Route path="user_detail/:id" element={<FetchUserDetail />} />
      <Route path="user_list/"      element={<FetchUserList />} />
      <Route path="user_pwchange/"  element={<FetchUserPwchange />} />
    </Routes>
  );
}
