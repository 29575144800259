import {useAuth} from "../AuthProvider";
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';

export default function LogList({data}) {
    const { setLogList } = useAuth();
    useEffect(() => {
        if (data) {
            setLogList(data);
        }
    }, [data, setLogList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            <Table bordered size="sm">
                <thead>
                    <tr>
                        <th rowSpan={2}>備蓄品<br />ID</th>
                        <th>備蓄品名</th>
                        <th>倉庫</th>
                    </tr>
                    <tr>
                        <th>入庫時刻</th>
                        <th>出庫時刻</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <LogRow key={item.id} item={item} />
                    ))}
                </tbody>
            </Table>

        </div>
    );
}

// 末尾の "+09:00" を削除して返す.
function del900(string) {
    return string.replace("+09:00", "");
}

// 末尾の .数字 を削除して返す.
function delDot(string) {
    return string.replace(/\.\d+$/, "");
}
// "T" を " " に置換して返す.
function replaceT(string) {
    return string.replace("T", " ");
}

function _rep(time_string) {
    return replaceT(delDot(del900(time_string)));
}

function LogRow({item}) {
    const uri = "/log_detail/" + item.id + "/";
    return (
        <>
        <tr>
            <td rowSpan={2}>{item.id}</td>
            <td>
                <a href={uri}>
                    {item.name}
                </a>
            </td>
            <td>{item.in_action.storage.name}</td>
        </tr>
        <tr>
            <td>
                {_rep(item.in_action.created_at)}
            </td>
            <td>
                {item.out_action && _rep(item.out_action.created_at)}
            </td>
        </tr>
        </>
    );
}
