import { get_role } from "../util";
import {useAuth} from "../AuthProvider";
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';

export default function UserList({data}) {
    const { setUserList } = useAuth();
    useEffect(() => {
        if (data) {
            setUserList(data);
        }
    }, [data, setUserList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            <div>
                並び替え:
                <a href="/user_list/?sort=id">ID (古い順)</a>
                |
                <a href="/user_list/?sort=email">メールアドレス</a>
                |
            </div>

            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>ユーザーID</th>
                        <th>名前</th>
                        <th>権限</th>
                        <th>メールアドレス</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(user => (
                        <UserRow key={user.id} user={user} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function UserRow({user}) {
    const role = get_role(user.role)
    return (
        <tr>
            <td>{user.id}</td>
            <td>
                <a href={user.uri}>
                    {user.name}
                </a>
            </td>
            <td>{role}</td>
            <td>{user.email}</td>
        </tr>
    );
}
