import { Routes, Route } from "react-router-dom";
import FetchLogDetail from "./FetchLogDetail";
import FetchLogList from "./FetchLogList";

export default function App() {
  return (
    <Routes>
      <Route path="log_detail/:id" element={<FetchLogDetail />} />
      <Route path="log_list/"      element={<FetchLogList />} />
    </Routes>
  );
}
