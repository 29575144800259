import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import UserDetail from "./UserDetail";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchUserDetail() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.USER, id)}
            renderSuccess={UserDetail}
        />
    );
}
