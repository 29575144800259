import Table from 'react-bootstrap/Table';

// MEMO: ({data}) なので， 引数の名前 (data) を変更してはいけない.
export default function LogDetail({data}) {
    const item = data;
    return (
        <Table bordered size="sm">
            <tbody>
                <tr>
                    <th>備蓄品ID</th>
                    <td>{item.id}</td>
                </tr>
                <tr>
                    <th>備蓄品名</th>
                    <td>
                        <a href={item.uri}>
                            {item.name}
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>賞味期限</th>
                    <td>{item.expire}</td>
                </tr>
                <tr>
                    <th>入庫</th>
                    <td>{item.in_action.created_at}</td>
                </tr>
                {item.out_action ?
                    <tr>
                        <th>出庫</th>
                        <td>{item.out_action.created_at}</td>
                    </tr>
                :
                    ""
                }
                <tr>
                    <th>倉庫</th>
                    <td>
                        <a href={item.in_action.storage.uri}>
                            {item.in_action.storage.name}
                        </a>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}
