import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserApp from "./Users";
import StorageApp from "./Storages";
import ItemApp from "./Items";
import LogApp from "./Logs";
import Login from "./Login";
import Logout from "./Logout";
import Status from "./Status";
import MyPage from "./MyPage";
import FetchScan from "./FetchScan";
import FetchQRList from "./FetchQRList";
import Header from "./Header";
import AuthProvider from "./AuthProvider";
import { TitleForPC } from './Title';
import * as serviceWorker from './serviceWorker';
import BootstrapSample from './BootstrapSample';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <Router>
      <Container fluid>
        <Row>
          <Header />
          <Col xs={12} md={9} className="right-column">
            <TitleForPC />
            <UserApp />
            <StorageApp />
            <ItemApp />
            <LogApp />
            <Routes>
              <Route path="/"         element={<MyPage />} />
              <Route path="qr/"       element={<FetchQRList />} />
              <Route path="scan/:id"  element={<FetchScan />} />
              <Route path="status/"   element={<Status />} />
              <Route path="bs/"       element={<BootstrapSample />} />
              <Route path="login/"    element={<Login />} />
              <Route path="logout/"   element={<Logout />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </Router>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
