import { Routes, Route } from "react-router-dom";
import StorageInsert from "./StorageInsert";
import FetchStorageUpdate from "./FetchStorageUpdate";
import FetchStorageDelete from "./FetchStorageDelete";
import FetchStorageDetail from "./FetchStorageDetail";
import FetchStorageList from "./FetchStorageList";

export default function App() {
  return (
    <Routes>
      <Route path="storage_insert/"    element={<StorageInsert />} />
      <Route path="storage_update/:id" element={<FetchStorageUpdate />} />
      <Route path="storage_delete/:id" element={<FetchStorageDelete />} />
      <Route path="storage_detail/:id" element={<FetchStorageDetail />} />
      <Route path="storage_list/"      element={<FetchStorageList />} />
    </Routes>
  );
}
