import { Routes, Route } from "react-router-dom";
import ItemInsert from "./ItemInsert";
import FetchItemUpdate from "./FetchItemUpdate";
import FetchItemDelete from "./FetchItemDelete";
import FetchItemDetail from "./FetchItemDetail";
import FetchItemList from "./FetchItemList";
import FetchItemResult from "./FetchItemResult";

export default function App() {
  return (
    <Routes>
      <Route path="item_insert/"    element={<ItemInsert />} />
      <Route path="item_update/:id" element={<FetchItemUpdate />} />
      <Route path="item_delete/:id" element={<FetchItemDelete />} />
      <Route path="item_detail/:id" element={<FetchItemDetail />} />
      <Route path="item_list/"      element={<FetchItemList />} />
      <Route path="item_result/"    element={<FetchItemResult />} />
    </Routes>
  );
}
