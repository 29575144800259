import Cookies from 'js-cookie';
import { _l, _d } from './util';

export class URL {
    static ACTION = {
        TOP:        "top",
        LOGIN:      "login",
        LOGOUT:     "logout",
        SCAN:       "scan",
        QR:         "qr",
        UPLOAD:     "upload",
        RESULT:     "result",  // CSV upload result.
        PWCHANGE:   "pwchange",
        INBOUND:    "inbound",
        OUTBOUND:   "outbound",
        INSERT:     "insert",
        UPDATE:     "update",
        DELETE:     "delete",
        LIST:       "list",
        DETAIL:     "detail",
    };

    static TYPE = {
        USER:       "user",
        STORAGE:    "storage",
        ITEM:       "item",
        LOG:        "log",
    };

    static STRINGS = {
        [URL.TYPE.USER]:        "ユーザー",        
        [URL.TYPE.STORAGE]:     "倉庫",
        [URL.TYPE.ITEM]:        "備蓄品",
        [URL.TYPE.LOG]:         "入出庫履歴",

        [URL.ACTION.TOP]:       "トップページ",
        [URL.ACTION.LOGIN]:     "ログイン",
        [URL.ACTION.LOGOUT]:    "ログアウト",
        [URL.ACTION.SCAN]:      "QRコードスキャン",
        [URL.ACTION.QR]:        "QRコード印刷",
        [URL.ACTION.UPLOAD]:    "CSVアップロード",
        [URL.ACTION.RESULT]:    "CSVアップロード結果",
        [URL.ACTION.PWCHANGE]:  "パスワード変更",
        [URL.ACTION.INBOUND]:   "入庫",
        [URL.ACTION.OUTBOUND]:  "出庫",
        [URL.ACTION.INSERT]:    "新規作成",
        [URL.ACTION.UPDATE]:    "編集",
        [URL.ACTION.DELETE]:    "削除",
        [URL.ACTION.LIST]:      "一覧",
        [URL.ACTION.DETAIL]:    "詳細",
    };

    constructor(action, type = null, id = 0, flags = null) {
        this.action = action;
        this.type   = type;
        this.id     = id;
        this.flags  = flags;
    }

    static needType(action) {
        if (action === URL.ACTION.TOP)      return false;
        if (action === URL.ACTION.LOGIN)    return false;
        if (action === URL.ACTION.LOGOUT)   return false;
        if (action === URL.ACTION.SCAN)     return false;
        if (action === URL.ACTION.QR)       return false;
        if (action === URL.ACTION.INBOUND)  return false;
        if (action === URL.ACTION.OUTBOUND) return false;
        return true;
    };

    static needID(action) {
        if (action === URL.ACTION.UPDATE) return true;
        if (action === URL.ACTION.DELETE) return true;
        if (action === URL.ACTION.DETAIL) return true;
        if (action === URL.ACTION.SCAN)   return true;
        return false;
    };

    static getHTTPMethod(action) {
        if (action === URL.ACTION.LOGIN)    return "POST";
        if (action === URL.ACTION.LOGOUT)   return "POST";
        if (action === URL.ACTION.UPLOAD)   return "POST";
        if (action === URL.ACTION.PWCHANGE) return "PUT";
        if (action === URL.ACTION.INBOUND)  return "POST";
        if (action === URL.ACTION.OUTBOUND) return "POST";
        if (action === URL.ACTION.INSERT)   return "POST";
        if (action === URL.ACTION.UPDATE)   return "PUT";
        if (action === URL.ACTION.DELETE)   return "DELETE";
        return "GET";
    };

    static parse(pathname) {
        const pathParts = pathname.split('/').filter(part => part);
        const path      = pathParts[0] || null;
        const id        = pathParts[1] || 0;
        const type      = URL.findType(path);
        const action    = URL.findAction(path);
        if (!action) return new URL(URL.ACTION.TOP);  // TOP page.
        return new URL(action, type, id);
    };

    static findType(path) {
        if (!path) return null;
        for (let type of Object.values(URL.TYPE)) {
            if (path.startsWith(type)) return type;
        }
        return null;
    }

    static findAction(path) {
        if (!path) return null;
        for (let action of Object.values(URL.ACTION)) {
            if (path.endsWith(action)) return action;
        }
        return null;
    }

    static makeHTTPHeader() {
        return {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'), // MEMO: GET の時は不要.
        };
    };

    makeFetchParameters(data = null) {
        const method    = URL.getHTTPMethod(this.action);
        const headers   = URL.makeHTTPHeader();
        let ret = {
            method:         method,
            headers:        headers,
            credentials:    'include', // セッションを維持するために必要
        };
        if (!data) return ret;
        const body      = JSON.stringify(data);
        ret.body = body;
        return ret;
    };

    async fetch(data = null) {
        const apiEndpoint   = this.getAPI();
        const params        = this.makeFetchParameters(data);
        _l("apiEndpoint: " + apiEndpoint);
        _d("params: ", params);
        return await fetch(apiEndpoint, params);
    }

    // shortcut.
    static PATH(action, type = null, id = 0, flags = null) {
        return new URL(action, type, id, flags).getPath();
    };

    // shortcut.
    static API(action, type = null, id = 0, flags = null) {
        return new URL(action, type, id, flags).getAPI();
    };

    makeFirstPath() {
        if (this.action === URL.ACTION.TOP) return "/";
        if (!URL.needType(this.action))     return "/" + this.action + "/"; // login, logout.
        else                                return "/" + this.type + "_" + this.action + "/";
    }

    getPathWithoutFlags() {
        let ret = this.makeFirstPath();
        if (URL.needID(this.action))        return ret + this.id + "/";
        else                                return ret;
    }

    getPath() {
        let ret = this.getPathWithoutFlags();
        if (!this.flags) return ret;
        ret += "?";
        for (let key in this.flags) {
            ret += key + "=" + this.flags[key] + "&";
        }
        if (ret.endsWith("&")) {
            ret = ret.slice(0, -1); // remove last "&".
        }
        return ret;
    }

    getAPI() {
        return "/api" + this.getPath();
    }

    getTitle() {
        if (URL.needType(this.action))  return URL.STRINGS[this.type] + URL.STRINGS[this.action];
        else                            return URL.STRINGS[this.action];
    }
};
export const TYPE   = URL.TYPE;
export const ACTION = URL.ACTION;
