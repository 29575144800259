import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import ItemDetail from "./ItemDetail";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchItemDetail() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.ITEM, id)}
            renderSuccess={ItemDetail}
        />
    );
}
