import {useAuth} from "../AuthProvider";
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';

export default function ItemList({data, displaySort=true}) {
    const { setItemList } = useAuth();
    useEffect(() => {
        if (data) {
            setItemList(data);
        }
    }, [data, setItemList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {displaySort &&
                <div>
                    並び替え:
                    <a href="/item_list/?sort=id">ID (古い順)</a>
                    |
                    <a href="/item_list/?sort=name">名前</a>
                    |
                    <a href="/item_list/?sort=expire">消費期限</a>
                    |
                    <a href="/item_list/?sort=storage_id">倉庫</a>
                    |
                </div>
            }
            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>備蓄品ID</th>
                        <th>名前</th>
                        <th>消費期限</th>
                        <th>倉庫</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <ItemRow key={item.id} item={item} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function ItemRow({item}) {
    return (
        <tr>
            <td>{item.id}</td>
            <td>
                <a href={item.uri}>
                    {item.name}
                </a>
            </td>
            <td>{item.expire && item.expire}</td>
            <td>{item.storage && <a href={item.storage.uri}>{item.storage.name}</a>}</td>
        </tr>
    );
}
