import {useAuth} from "../AuthProvider";
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';

export default function StorageList({data, displaySort=true}) {
    const { setStorageList } = useAuth();
    useEffect(() => {
        if (data) {
            setStorageList(data);
        }
    }, [data, setStorageList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {displaySort &&
                <div>
                    並び替え:
                    <a href="/storage_list/?sort=id">ID (古い順)</a>
                    |
                    <a href="/storage_list/?sort=name">名前</a>
                    |
                </div>
            }

            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>倉庫ID</th>
                        <th>名前</th>
                        <th>住所</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(storage => (
                        <StorageRow key={storage.id} storage={storage} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function StorageRow({storage}) {
    return (
        <tr>
            <td>{storage.id}</td>
            <td>
                <a href={storage.uri}>
                    {storage.name}
                </a>
            </td>
            <td>{storage.address}</td>
        </tr>
    );
}
