import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Fetch from "./Fetch";
import { useAuth } from "./AuthProvider";
import {URL, ACTION} from './URL';


export default function FetchLogout() {
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        logout();
        navigate(URL.PATH(ACTION.LOGIN));
    }, [logout]); // 依存配列に logout を追加

    return (
        <Fetch
            url={new URL(ACTION.LOGOUT)}
            renderSuccess={Logout}
        />
    );
}

function Logout({data}) {
    return null;
}
