import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import UserUpdate from "./UserUpdate";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchUserUpdate() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.USER, id, {"to_update" : 1})}
            /* 
            * UserUpdate 内で useForm を使っているためこのように書く.
            * (Rendered more hooks than during the previous render というエラー対策)
            */
            renderSuccess={(data) => <UserUpdate data={data.data} />}
        />
    );
}
