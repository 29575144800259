import React, { createContext, useState, useContext } from "react";
import { ROLE_USER, ROLE_ADMIN } from "./util";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [isAuthenticated, setAuthenticated] = useState(is_logged_in());
  const [isAdmin,         setAdmin]         = useState(is_admin());
  const [userList,        setUserList]      = useState([]); // 本当は DataProvider を作成したほうがいいのかもしれない.
  const [storageList,     setStorageList]   = useState([]);
  const [itemList,        setItemList]      = useState([]);
  const [logList,         setLogList]       = useState([]);

  const login = (user_id, role) => {
    set_user_id(user_id, role);
    setAuthenticated(true);
    setAdmin(role == ROLE_ADMIN);
  };

  const logout = () => {
    set_user_id(0);
    setAuthenticated(false);
    setAdmin(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        login,
        logout,
        userList,
        setUserList,
        storageList,
        setStorageList,
        itemList,
        setItemList,
        logList,
        setLogList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function is_logged_in() {
    const userId = get_user_id();
    return !!userId;
}

export function is_admin() {
    return get_role() == ROLE_ADMIN;
}

export function set_user_id(user_id, role=ROLE_USER) {
  if (user_id <= 0) {
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
  } else {
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("role",    role);
  }
}

export function get_user_id() {
  return localStorage.getItem("user_id") || 0;
}

export function get_role() {
  return localStorage.getItem("role") || ROLE_USER;
}
