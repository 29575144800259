import {useFetch} from "./hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { URL, ACTION } from "./URL";

// const BASE_URI = "http://localhost:8000/"
const LOADING_FALLBACK = <p>Loading...</p>
const RENDER_ERROR = error => <p>Error. </p>

export default function Fetch({url, renderSuccess, loadingFallback = LOADING_FALLBACK, renderError = RENDER_ERROR}) {
    const {loading, error, data} = useFetch(url);
    const navigate = useNavigate();
    const { logout } = useAuth();

    if (error && error.is_403) {
        logout();
        navigate(URL.PATH(ACTION.LOGIN));
        return null;
    }
    if (error)      return renderError(error);
    if (loading)    return loadingFallback;
    if (data)       return renderSuccess({data});
    return null;
}
