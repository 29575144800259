import StorageList from "../Storages/StorageList";
import { get_role } from "../util";
import Table from 'react-bootstrap/Table';

export default function UserDetail({data}) {
    return (
        <>
            <h3>■ユーザー情報</h3>
            <Table bordered size="sm">
                <tbody>
                    <UserDetailBase data={data} />
                </tbody>
            </Table>
            <h3>■管理している倉庫一覧</h3>
            <StorageList data={data.storages} displaySort={false} />
        </>
    );
}

export function UserDetailBase({data}) {
    return (
        <>
            <tr>
                <th>ユーザーID</th>
                <td>{data.id}</td>
            </tr>
            <tr>
                <th>名前</th>
                <td>{data.name}</td>
            </tr>
            <tr>
                <th>メールアドレス</th>
                <td>{data.email}</td>
            </tr>
            <tr>
                <th>権限</th>
                <td>{get_role(data.role)}</td>
            </tr>
        </>                
    );
}
