import { useLocation } from "react-router-dom";
import Fetch from "../Fetch";
import ItemList from "./ItemList";
import { URL, TYPE, ACTION } from "../URL";
import { get_sort_flags } from "../util";

export default function FetchItemList() {
    const flags = get_sort_flags(useLocation());
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.ITEM, 0, flags)}
            renderSuccess={(data) => <ItemList data={data.data} />}
        />
    );
}
