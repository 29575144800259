function isPassword(name) {
  const n = name.toLowerCase();
  return n.includes('password');
}

export function InputTag({ name, formData, onChange, register }) {
  const type = isPassword(name) ? 'password' : 'text';
  return (
    <input
      type          = {type}
      id            = {name}
      name          = {name}
      defaultValue  = {formData[name]}
      onChange      = {onChange}
      {...register(name)}
      />
  );
}

export function SelecttTag({ name, formData, onChange, register, options }) {
  return (
    <select
      id            = {name}
      name          = {name}
      defaultValue  = {formData[name]}
      onChange      = {onChange}
      {...register(name)}
      >
      {options.map((option) => (
        <option key={option.key} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export function Input({ label, name, formData, setFormData, register, errors, options }) {
  const onChange = (e) => {
    // @see useEditForm.js フィールドの値が変更されたときにフォームデータを更新
    setFormData({ ...formData, [name]: e.target.value });
  };

  return (
    <>
      <th>
        <label htmlFor={name}>
          {label}
        </label>
      </th>
      <td>
        {options ? (
          <SelecttTag
            name      = {name}
            formData  = {formData}
            onChange  = {onChange}
            register  = {register}
            options   = {options}
            />
        ) : (
          <InputTag
            name      = {name}
            formData  = {formData}
            onChange  = {onChange}
            register  = {register}
            />
        )}
        {errors[name] && <div className="error">{errors[name].message}</div>}
      </td>
    </>      
  );
}
  