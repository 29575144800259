import { useParams } from "react-router-dom";
import Fetch from "../Fetch";
import LogDetail from "./LogDetail";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchLogDetail() {
    let { id } = useParams();
    return (
        <Fetch
            url={new URL(ACTION.DETAIL, TYPE.LOG, id)}
            renderSuccess={LogDetail}
        />
    );
}
