import iconv from 'iconv-lite';
import { URL, ACTION, TYPE } from "./URL";
import { _l, get_role } from "./util";
import Cookies from 'js-cookie';

export default class CSV {
    static downloadCSV(anchorRef, csv, filename) {
        const link = anchorRef.current
        if (!link) return
    
        const sjisArray = iconv.encode(csv, 'Shift_JIS');
        const blob = new Blob([sjisArray], { type: 'text/csv;charset=Shift_JIS;' });
        const url = window.URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.click()
    };

    static makeCSVStringUser(userList) {
        const header    = ["ID", "名前", "メールアドレス", "権限", ]
        const rows      = userList.map(user => [user.id, user.name, user.email, get_role(user.role)]);
        return CSV.joinHeaderAndRows(header, rows);
    }
    
    static makeCSVStringStorage(storageList) {
        const header    = ["ID", "名前", "住所", ]
        const rows      = storageList.map(storage => [storage.id, storage.name, storage.address, ]);
        return CSV.joinHeaderAndRows(header, rows);
    }
    
    static makeCSVStringItem(itemList) {
        const header    = ["ID", "名前", "消費期限", "倉庫ID", "倉庫名", ]
        const rows      = itemList.map(item => [
            item.id,
            item.name,
            item.expire,
            item.storage ? item.storage.id : "",
            item.storage ? item.storage.name : "",
        ]);
        return CSV.joinHeaderAndRows(header, rows);
    }
    
    static makeCSVStringLog(logList) {
        const header    = [
            "備蓄品ID",
            "備蓄品名",
            "倉庫ID",
            "倉庫名",
            "入庫履歴ID",
            "入庫時刻",
            "入庫ユーザーID",
            "入庫ユーザー名",
            "出庫履歴ID",
            "出庫時刻",
            "出庫ユーザーID",
            "出庫ユーザー名",
        ]
        const rows      = logList.map(log => [
            log.in_action.item.id,
            log.in_action.item.name,
            log.in_action.storage.id,
            log.in_action.storage.name,
            log.in_action.id,
            log.in_action.created_at,
            log.in_action.user.id,
            log.in_action.user.name,
            log.out_action ? log.out_action.id : "",
            log.out_action ? log.out_action.created_at : "",
            log.out_action ? log.out_action.user.id : "",
            log.out_action ? log.out_action.user.name : "",
        ]);
        return CSV.joinHeaderAndRows(header, rows);
    }
    
    static joinHeaderAndRows(header, rows) {
        return  [header, ...rows].map(row => row.join(",")).join("\r\n");
    }
    
    static makeCSVString(url, userList, storageList, itemList, logList) {
        if (url.type === TYPE.USER)    return CSV.makeCSVStringUser(userList);
        if (url.type === TYPE.STORAGE) return CSV.makeCSVStringStorage(storageList);
        if (url.type === TYPE.ITEM)    return CSV.makeCSVStringItem(itemList);
        if (url.type === TYPE.LOG)     return CSV.makeCSVStringLog(logList);
        return "";
    }

    static getCurrentTimestampString() {
        const now       = new Date();
        const year      = now.getFullYear();
        const month     = String(now.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1
        const day       = String(now.getDate()).padStart(2, '0');
        const hours     = String(now.getHours()).padStart(2, '0');
        const minutes   = String(now.getMinutes()).padStart(2, '0');
        const seconds   = String(now.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }

    static makeDownloadFileName(url) {
        const filename  = url.type + "_" + CSV.getCurrentTimestampString() + ".csv";
        return filename;
    }

    // TODO: refactoring.
    static uploadCSV(event, navigate) {
        const file = event.target.files[0];
        if (!file)   return;

        // アップロード処理をここに追加
        console.log('ファイルが選択されました:', file);
        // 例: ファイルをサーバーにアップロードする処理
        const formData = new FormData();
        formData.append('file', file);

        const followRedirect = (response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (response.redirected) {
                const redirectUrl = new window.URL(response.url);
                console.log("following redirect: ", redirectUrl.href);
                navigate(redirectUrl.pathname + redirectUrl.search + redirectUrl.hash);
            } else {
                console.log("No redirect, response status: ", response.status);
            }            
        };

        const url           = new URL(ACTION.UPLOAD, TYPE.ITEM);
        const apiEndpoint   = url.getAPI();
        const params = {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'), // MEMO: GET の時は不要.
            },
        };
        console.dir(params);

        fetch(apiEndpoint, params)
        .then(followRedirect)
        .catch(error => {
            console.error('アップロードエラー:', error);
        });
    }
};
