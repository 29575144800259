import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { _l, _d } from "./util";
import { URL, ACTION } from "./URL";

function handleSuccess(data, names, type, action, navigate) {
  _l(type + " " + action + " SUCCESS");
  const new_action = action === ACTION.DELETE ? ACTION.LIST : ACTION.DETAIL;
  navigate(URL.PATH(new_action, type, data.id));
}

function handleServerErrors(data, names, type, action, setError) {
  _l(type + " " + action + " FAILED: SERVER ERRORS");
  if (!data.details) return
  for (const [key, messages] of Object.entries(data.details)) {
    console.log(`Key: ${key}, Value: ${messages}`);
    const message = messages.map((msg, index) => <p key={index}>{msg}</p>);
    setError(key, { type: "server", message: message });
  } 
}

function handleOtherErrors(data, names, type, action, setError) {
  _l(type + " " + action + " FAILED: OTHER ERRORS");
  setError("non_field_errors", { type: "other", message: "エラーが発生しました" });
}

function doNothing(data) {
  return data;
}

export function useEditForm(data, names, type, action, id, correctData=doNothing) {
  const navigate = useNavigate();
  const { register, handleSubmit, setError, formState: { errors }, } = useForm();
  const [formData, setFormData] = useState(data);

  const onSubmit = handleSubmit(async (formData) => {
    const url         = new URL(action, type, id);
    const response    = await url.fetch(correctData(formData));

    if (response.ok) {
      const data    = await response.json();
      const status  = data.status;
      _l("HTTP response OK: status = " + status);
      _d("data: ", data);
      if (status === "OK")      return handleSuccess(     data, names, type, action, navigate);
      if (status === "FAILED")  return handleServerErrors(data, names, type, action, setError);
    }
    return handleOtherErrors(data, names, type, action, setError);
  });

  return {
    register,
    handleSubmit: onSubmit,
    errors,
    formData,
    setFormData,
  };
}
