import Table from 'react-bootstrap/Table';

export default function ItemDetail({data}) {
    return (
        <>
        <h3>■備蓄品情報</h3>
        <Table bordered size="sm">
            <tbody>
                <tr>
                    <th>備蓄品ID</th>
                    <td>{data.id}</td>
                </tr>
                <tr>
                    <th>備蓄品名</th>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <th>消費期限</th>
                    <td>{data.expire}</td>
                </tr>
                {data.storage ? (<>
                    <tr>
                        <th>倉庫ID</th>
                        <td>{data.storage.id}</td>
                    </tr>
                    <tr>
                        <th>倉庫名</th>
                        <td>
                            <a href={data.storage.uri}>
                                {data.storage.name}
                            </a>
                        </td>
                    </tr>                    
                    <tr>
                        <th>倉庫住所</th>
                        <td>{data.storage.address}</td>
                    </tr>
                </>
                ) : (
                    <tr>
                        <th>倉庫</th>
                        <td>なし</td>
                    </tr>
                )}
            </tbody>
        </Table>
        </>
    );
}
