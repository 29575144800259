import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_user_id } from './AuthProvider';
import { URL, TYPE, ACTION } from './URL';

function get_redirect_page_url() {
    const userId = get_user_id();
    if (userId) return URL.PATH(ACTION.DETAIL, TYPE.USER, userId);
    else        return URL.PATH(ACTION.LOGIN);
}

const MyPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(get_redirect_page_url());
  }, [navigate]);

  return null; // このコンポーネントは何もレンダリングしない
};

export default MyPage;
