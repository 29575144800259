export function _l(msg) {
    console.log(msg);
}

export function _d(data, msg = "... dir ...") {
    console.log(msg);
    console.dir(data);
}

export const ROLE_USER     = 0;
export const ROLE_ADMIN    = 1;
export const ROLE_DEFAULT  = ROLE_USER;
export const ROLES = [
    "ユーザー",
    "管理者",
];

export function get_role(role) {
    if (role === undefined)     return ROLES[ROLE_DEFAULT];
    if (role < 0)               return ROLES[ROLE_DEFAULT];
    if (role >= ROLES.length)   return ROLES[ROLE_DEFAULT];
    return ROLES[role];
}

export function get_role_if_admin(role) {
    if (role === ROLE_ADMIN) return "(" + ROLES[role] + ")";
    return "";
}

export function make_role_options() {
    let options = [];
    for (let i = 0; i < ROLES.length; i++) {
        const key = i;
        const value = i;
        const label = ROLES[i];
        options.push({key, value, label });
    }
    return options;
}

const QR_CODE_TOPKEN_PREFIX = "jIx2PQ9g_";
export function get_qr_code_token(item) {
    return QR_CODE_TOPKEN_PREFIX + item.id;
}

export function parse_qr_code(qr_code_token) {
    if (!qr_code_token.startsWith(QR_CODE_TOPKEN_PREFIX)) return 0;
    const item_id = qr_code_token.substring(QR_CODE_TOPKEN_PREFIX.length);
    return parseInt(item_id, 10);
}

export function get_sort_flags(location) {
    const params    = new URLSearchParams(location.search);
    const sort      = params.get("sort", null);
    if (sort)   return { sort };
    else        return {};
}
