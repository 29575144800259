import React, { useEffect } from "react";
import { useEditForm } from "../useEditForm";
import { Input } from "../Input";
import { TYPE, ACTION } from "../URL";
import Button from 'react-bootstrap/Button';

export default function ItemUpdate({ data }) {
  // _d(data, "data");
  const fields = [
    { name: "name",     label: "名前" },
    { name: "expire",   label: "消費期限" },
  ];

  const names   = fields.map(field => field.name);
  const item_id = data.id;
  const { register, handleSubmit, errors, formData, setFormData } = useEditForm(data, names, TYPE.ITEM, ACTION.UPDATE, item_id);

  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);

  return (
    <form onSubmit={handleSubmit}>
      <table>
        <tbody>

          <tr>
            <th>ID</th>
            <td>{data.id}</td>
          </tr>

          {fields.map((field) => (
            <tr key={field.name}>
              <Input
                name        = {field.name}
                label       = {field.label}
                options     = {field.options}
                formData    = {formData}
                setFormData = {setFormData}
                register    = {register}
                errors      = {errors}
              />
            </tr>
          ))}

        </tbody>
      </table>
      <Button type="submit">変更</Button>
    </form>
  );
}
