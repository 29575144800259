import {useState, useEffect} from "react";

export function useFetch(url) {
    const [data, setData]       = useState([]);
    const [error, setError]     = useState();
    const [loading, setLoading] = useState(true);

    function checkStatus(data) {
        if (data.status === 403) {
            const error = new Error("403 Forbidden");
            error.is_403 = true;
            throw error;
        }
        if (!data.ok)           throw new Error("HTTP STATUS ERROR: " + data.status);
    }

    function handleData(data) {
        checkStatus(data);
        return data.json();
    }

    /*
    function handleDataWithLog(data) {
        if (data.url) {
            console.log("url: " + data.url);
        }
        console.log("data is...");
        console.dir(data);
        // MEMO: data.json() returns a Promise object.
        const promiseObject = data.json();
        console.log("promiseObject is...");
        console.dir(promiseObject);
        checkStatus(data);
        return promiseObject.then(jsonData => {
            console.log("jsonData is ...");
            console.dir(jsonData);
            return jsonData;
        });
    }
    */

    useEffect(() => {
        url.fetch()
            .then(handleData)
            .then(setData)
            .then(() => setLoading(false))
            .catch(setError);
    }, [url]);
    return {loading, error, data};
}
