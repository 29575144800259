import { useState } from "react";
import { useZxing } from "react-zxing";
import { _d, _l, parse_qr_code } from "./util";
import { get_user_id } from "./AuthProvider";
import { URL } from "./URL";
import {Howl} from 'howler';
import Table from 'react-bootstrap/Table';

export const BarcodeScanner = ({url, storage}) => {
  _l("storage_id = " + storage.id);
  const bound     = URL.STRINGS[url.action];
  const [item_ids, setItemIds]  = useState([]);
  const [messages, setMessages] = useState([]);


  const sound_read = new Howl({
    src: ['/static/read.mp3'],
    preload: true,
  });
  const sound_register = new Howl({
    src: ['/static/register.mp3'],
    preload: true,
  });
  const sound_fail = new Howl({
    src: ['/static/fail.mp3'],
    preload: true,
  });

  /*
  sound.on('end', function(){
    _l('sound end!');
  });
  const onClickPlay = () => {
    sound.play();
  };
  */

  const putMessage = (message) => {
    const now = new Date();
    const formattedTime = now.toTimeString().split(' ')[0]; // HH:MM:SS 形式にフォーマット
    const timestampedMessage = `${formattedTime} ${message}`;
    setMessages((messages) => [timestampedMessage, ...messages]);
  };
  
  const requestAPI = async (request_data) => {
    putMessage("サーバーにリクエスト中... 備蓄品ID = " + request_data.item_id);
    const response  = await url.fetch(request_data);
    if (response.ok) {
      const data    = await response.json();
      _d(data, "response data: ");
      if (data.status === "OK") {
        putMessage(bound + " 成功: 備蓄品ID = " + request_data.item_id);
        sound_register.play();
        return;
      }
      if (data.status === "FAILED") {
        const details = data.details;
        putMessage(bound + " 失敗: 備蓄品ID = " + request_data.item_id + ", " + details.msg);
        sound_fail.play();
        return;
      }
    }
  };

  const { ref } = useZxing({
    onDecodeResult(result) {
      _l("onDecodeResult: " + result.text);
      const item_id = parse_qr_code(result.text);
      if (!item_id)                   return;
      if (item_ids.includes(item_id)) return;
      // putMessage("item_id = " + item_id);
      const data = {
        user_id:    get_user_id(),
        storage_id: storage.id,
        item_id:    item_id,
      };
      sound_read.play();
      setItemIds(prevItemIds => [...prevItemIds, item_id]);
      requestAPI(data);
    },
  });

  return (
    <div className="scan-container">
      <div className="scan-top">
        <h3 className="scan-bound">{bound}</h3>
        <h3 className="scan-storage-name">{storage.name}</h3>
        <video height="100%" ref={ref} />
      </div>
      <div  className="scan-bottom">
        <Table striped bordered size="sm">
        {messages.map((message, index) => (
          <tr key={index}>
            <td>
              {message}
            </td>
          </tr>
        ))}
        </Table>
      </div>
    </div>
  );
};
