import { useState, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { useLocation } from "react-router-dom";
import { URL } from "./URL";

export function Title() {
    const location      = useLocation();
    const [url, setURL] = useState(URL.parse(location.pathname));
    const { isAuthenticated, isAdmin } = useAuth();

    // location の変更を useEffect で監視する.
    useEffect(() => {
        setURL(URL.parse(location.pathname));
    }, [location]);

    if (!isAuthenticated) return "";

    const makeTitle = (isAdmin, url) =>{
        if (!url)       return "";
        const title = url.getTitle();
        if (isAdmin)    return "[管] " + title;
        else            return title;
    }

    const appendTitle = (isAdmin, url) => {
        const title = makeTitle(isAdmin, url);
        if (!title) return "";
        else        return <h1 className="text-center">{title}</h1>;
    }

    return <>{appendTitle(isAdmin, url)}</>;
}

export function TitleForPhone({children}) {
    return (
        <div className="d-md-none d-flex justify-content-between w-100">
            <div className="mx-auto">
                <Title />
            </div>
            {children}
        </div>
    );    
};

export function TitleForPC() {
    return (
        <div className="d-none d-md-block">
            <Title />
        </div>
    );
}
