import React, { useEffect } from "react";
import { useEditForm } from "../useEditForm";
import { Input } from "../Input";
import { TYPE, ACTION } from "../URL";
import { UserDetailBase } from "./UserDetail";
import Button from 'react-bootstrap/Button';

export default function UserPwchange({ data }) {
  // _d(data, "data");
  const fields = [
    { name: "old_password",     label: "現在のパスワード" },
    { name: "new_password1",    label: "新しいパスワード" },
    { name: "new_password2",    label: "新しいパスワード (再入力)" },
  ];

  const names   = fields.map(field => field.name);
  const user_id = data.id;
  const { register, handleSubmit, errors, formData, setFormData } = useEditForm(data, names, TYPE.USER, ACTION.PWCHANGE, user_id);

  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);

  return (
    <form onSubmit={handleSubmit}>
      <table>
        <tbody>

          <UserDetailBase data={data} />

          {fields.map((field) => (
            <tr key={field.name}>
              <Input
                name        = {field.name}
                label       = {field.label}
                options     = {field.options}
                formData    = {formData}
                setFormData = {setFormData}
                register    = {register}
                errors      = {errors}
              />
            </tr>
          ))}

        </tbody>
      </table>
      {errors["non_field_errors"] && <div className="error">{errors["non_field_errors"].message}</div>}
      <Button type="submit">変更</Button>
    </form>
  );
}
